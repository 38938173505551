import { animated, useSpring } from "react-spring"
import { interpolateTrans, xysCalc } from "../../common/utils"

import React from "react"

function AnimatedCard({ className, children }) {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 150, friction: 20, clamp: true },
  }))

  return (
    <animated.div
      className={className}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: xysCalc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(interpolateTrans) }}
    >
      {children}
    </animated.div>
  )
}

AnimatedCard.defaultProps = {
  className: "flex max-w-sm shadow-lg bg-white m-2 w-full",
}

export default AnimatedCard
