import { AnalyticsContext, BuildContext } from "../common/utils"
import CommonAnalytics, { PAGES } from "../common/analytics"
import React, { useContext } from "react"

import AsSeenOn from "../common/components/AsSeenOn"
import Landing from "../common/components/Landing"
import Layout from "../layout/components/Layout"
import RecentArticles from "../articles/components/RecentArticles"
import SEO from "../layout/components/SEO"
import { graphql } from "gatsby"

function IndexPage({ data, location }) {
  const { contentfulLanding } = data
  const { blurbs, asSeenOn, ...landingProps } = contentfulLanding
  const { branch, ctx } = useContext(BuildContext)

  const analytics = {
    ...CommonAnalytics.pageProperties(PAGES.LANDING),
    ...CommonAnalytics.netlifyProperties(branch, ctx),
  }

  return (
    <AnalyticsContext.Provider value={analytics}>
      <Layout loadUserOnCheckAuth={true}>
        <SEO title="Home" url={location.href} />

        <Landing {...landingProps} />

        {!!asSeenOn && <AsSeenOn logos={asSeenOn} />}

        <RecentArticles />
      </Layout>
    </AnalyticsContext.Provider>
  )
}

export default IndexPage
export const query = graphql`
  query landingPageQuery {
    contentfulLanding {
      id
      subtitle
      title
      version
      description
      image {
        file {
          url
        }
      }
      logo {
        fluid(quality: 100, maxWidth: 150, maxHeight: 150) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      playStoreIcon {
        file {
          url
        }
      }
      appStoreIcon {
        file {
          url
        }
      }
      asSeenOn {
        fluid(quality: 100, maxHeight: 150) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
