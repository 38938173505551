import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

function LargeLinkToButton({ to, onClick, text }) {
  return (
    <Link
      to={to}
      onClick={onClick}
      className="border p-4 text-2xl hover:bg-gray-200 font-semibold text-gray-600 text-center"
    >
      {text}
    </Link>
  )
}

LargeLinkToButton.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

LargeLinkToButton.defaultProps = {
  onClick: () => {},
  text: "Click Me",
}

export default LargeLinkToButton
