import NonStretchedImage from "../../common/components/NonStretchedImage"
import React from "react"

export default ({ imageData, author, publishedDate }) => {
  return (
    <div className="flex flex-row w-full p-4">
      <NonStretchedImage
        fluid={imageData}
        style={{ borderRadius: "50%", width: 40, height: 40 }}
      />
      <small className="text-gray-600 flex-col pl-2 w-3/4 self-center text-left">
        {author.fullName}
        {!!publishedDate ? `, on ${publishedDate}` : null}
      </small>
    </div>
  )
}
