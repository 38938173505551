import * as selectors from "../store/selectors"

import CommonAnalytics, { EVENTS } from "../../common/analytics"
import React, { useContext } from "react"

import { AnalyticsContext } from "../../common/utils"
import ArticleList from "./ArticleList"
import RecentItemsContainer from "../../common/components/RecentItemsContainer"
import { connect } from "react-redux"

const RecentArticles = ({ entities }) => {
  const analytics = useContext(AnalyticsContext)

  const onClick = () =>
    CommonAnalytics.trackEvent(EVENTS.READ_MORE_ARTICLES_CLICKED, analytics)

  return (
    <RecentItemsContainer
      title="Recent Articles"
      linkTo="/articles"
      onClick={onClick}
      linkText="More articles"
      showLoadButton={entities.length > 0}
    >
      <ArticleList entities={entities} />
    </RecentItemsContainer>
  )
}

const mapStateToProps = state => ({
  entities: selectors.recentArticles$(state),
})

export default connect(mapStateToProps)(RecentArticles)
