import CommonAnalytics, { EVENTS, PROPERTIES } from "../analytics"
import React, { useContext } from "react"

import { AnalyticsContext } from "../utils"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const ITUNES = "iTunes Store"
const PLAY = "Play Store"

function StoreButton({ storeUrl, image, alt }) {
  const analytics = useContext(AnalyticsContext)
  const onClick = () => {
    CommonAnalytics.trackEvent(EVENTS.APP_STORE_LINK_CLICKED, {
      ...analytics,
      [PROPERTIES.STORE_NAME]: alt,
    })
  }

  return (
    <OutboundLink href={storeUrl} className="mr-5" onClick={onClick}>
      <img src={image.file.url} alt={alt} style={{ width: 150 }} />
    </OutboundLink>
  )
}

function StoreButtons({ playStoreIcon, appStoreIcon }) {
  return (
    <div className="flex flex-row w-full">
      <StoreButton
        alt={ITUNES}
        storeUrl="https://itunes.apple.com/au/app/whatsmine-rewards-loyalty/id1434359645?ls=1&amp;mt=8"
        image={appStoreIcon}
      />
      <StoreButton
        alt={PLAY}
        storeUrl="https://play.google.com/store/apps/details?id=com.scentregroup.mine"
        image={playStoreIcon}
      />
    </div>
  )
}

export default StoreButtons
