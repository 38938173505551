import NonStretchedImage from "../../common/components/NonStretchedImage"
import React from "react"
import { StyledHeroTitle } from "./styled/hero"

export default ({ logos }) => (
  <div className="gradient mb-12 pb-12">
    <div className="container mx-auto">
      <div className="flex-col w-1/2 px-2 py-8">
        <StyledHeroTitle>As seen on</StyledHeroTitle>
      </div>
      <div className="flex flex-col md:flex-row md:flex-wrap justify-center items-center md:justify-start md:items-start w-full px-2 md:px-0">
        {logos.map((logo, index) => (
          <div
            className="flex-row md:flex-col pb-6 md:pr-6 w-2/5 md:w-1/5"
            key={`seen-on-logo-${index}`}
          >
            <NonStretchedImage
              fluid={logo.fluid}
              alt="As seen on logo"
              className="mr-4"
            />
          </div>
        ))}
      </div>
    </div>
  </div>
)
