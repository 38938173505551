import EntitiesContainer from "./EntitiesContainer"
import HeroContainer from "./HeroContainer"
import LargeLinkToButton from "./LargeLinkToButton"
import React from "react"
import { StyledHeroTitle } from "./styled/hero"

const RecentItemsContainer = ({
  title,
  linkTo,
  onClick,
  linkText,
  children,
  showLoadButton,
}) => {
  return (
    <EntitiesContainer>
      {children}
      {showLoadButton ? (
        <div className="w-full flex flex-row justify-center">
          <LargeLinkToButton to={linkTo} onClick={onClick} text={linkText} />
        </div>
      ) : null}
    </EntitiesContainer>
  )
}

export default RecentItemsContainer
