import { BrowserView, MobileView } from "react-device-detect"

import React from "react"
import StoreButtons from "./StoreButtons"
import get from "lodash/get"
import styled from "styled-components"

function Landing({
  subtitle,
  title,
  description,
  logo,
  playStoreIcon,
  appStoreIcon,
  className,
  image,
}) {
  const coverImageUrl = get(image, "file.url")
  return (
    <>
      <BrowserView>
        <div
          className={className}
          style={{
            backgroundImage: `url(${coverImageUrl})`,
            backgroundSize: "cover",
          }}
        >
          <div className="container mx-auto">
            <div className="flex flex-col w-full md:w-3/5 lg:w-2/5 px-4 bg-white opacity-75 text-brand">
              <h1 className="mb-6 w-full md:w-4/5 mt-4 text-3xl md:text-5xl font-medium leading-tight">
                {title}
              </h1>
              <p className="uppercase text-lg md:text-xl font-light">
                {subtitle}
              </p>
              <p className="text-lg md:text-xl mt-4 w-full md:w-2/3 font-light">
                {description}
              </p>
              <StoreButtons
                playStoreIcon={playStoreIcon}
                appStoreIcon={appStoreIcon}
              />
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="py-6">
          <div className="container mx-auto">
            <div className="flex flex-col w-full px-2 md:px-0 mx-auto">
              <h1 className="mb-6 w-full md:w-2/5 mt-4 text-3xl md:text-5xl font-semibold leading-tight text-gray-700">
                {title}
              </h1>
              <p className="uppercase text-lg md:text-xl text-gray-700">
                {subtitle}
              </p>
              <p className="text-lg md:text-xl mt-4 text-gray-700 w-full md:w-2/5">
                {description}
              </p>
              <StoreButtons
                playStoreIcon={playStoreIcon}
                appStoreIcon={appStoreIcon}
              />
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}

const StyledLayout = styled(Landing)`
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-position: top;
`

export default StyledLayout
