import React from "react"

const ResponsiveCard = ({ className, children }) => (
  <div className={className}>{children}</div>
)

ResponsiveCard.defaultProps = {
  className: "flex w-full md:w-1/2 lg:w-1/3 xl:w-1/4 justify-center",
}

export default ResponsiveCard
