import { BrowserView } from "react-device-detect"

import PropTypes from "prop-types"
import React from "react"

function CardDetails({ title, description, programName }) {
  return (
    <div className="p-4 flex-grow">
      <div className="flex-row text-medium mb-2 text-lg text-left">{title}</div>
      <BrowserView>
        <p className="flex-row text-gray-600 font-light text-sm text-left line-clamp">
          {description}
        </p>
      </BrowserView>
    </div>
  )
}

CardDetails.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default CardDetails
