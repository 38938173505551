import Card from "./Card"
import React from "react"
import { StyledEntitiesList } from "../../common/components/styled/entities"

function ArticleList({ entities }) {
  return (
    <StyledEntitiesList>
      {entities
        .filter(item => item.id !== "dummy")
        .map(item => (
          <Card key={item.id} item={item} />
        ))}
    </StyledEntitiesList>
  )
}

ArticleList.defaultProps = {
  entities: [],
}

export default ArticleList
