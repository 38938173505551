import AnimatedCard from "../../common/components/AnimatedCard"
import ArticleFooter from "./ArticleFooter"
import CardDetails from "../../common/components/CardDetails"
import { Link } from "gatsby"
import LinkedCardContainer from "../../common/components/LinkedCardContainer"
import NonStretchedImage from "../../common/components/NonStretchedImage"
import React from "react"
import ResponsiveCard from "../../common/components/ResponsiveCard"
import get from "lodash/get"
import path from "path"

const Card = ({ item }) => {
  const { uuid, title, slug, image, publishedDate, author, blurb } = item
  const avatarImageData = get(author, "avatar.fluid")
  const imageData = get(image, "fluid")
  const url = path.join("/", "articles", slug)

  return (
    <ResponsiveCard>
      <AnimatedCard className="flex max-w-sm shadow-lg bg-white m-2 w-full border border-gray-400">
        <Link to={url} className="flex flex-col w-full">
          <NonStretchedImage
            fluid={imageData}
            alt={title}
            style={{ height: 300 }}
          />

          <div className="flex flex-col flex-grow items-start justify-start">
            <CardDetails title={title} description={blurb} />
            <ArticleFooter
              imageData={avatarImageData}
              author={author}
              publishedDate={publishedDate}
            />
          </div>
        </Link>
      </AnimatedCard>
    </ResponsiveCard>
  )
}

export default Card
