import { filter$, search$ } from "../../../search/store/selectors"

import { DEFAULT_PAGE_SIZE } from "../../../common/utils"
import SearchFields from "../../../search/utils/search-fields"
import { createSelector } from "reselect"
import get from "lodash/get"
import { searchableTextFromProperties } from "../../../search/utils"

const defaultPageInfo = {
  offset: 0,
  count: 0,
}

const entitiesState = state => state.entities
const articlesState = state => state.articles
const pageInfoState = state => state.pageInfo

export const selectedArticleId$ = (state, props) => props.articleId

export const savedArticleIds$ = createSelector(
  [articlesState],
  state => state.saved
)

export const isArticleSaved$ = createSelector(
  [selectedArticleId$, savedArticleIds$],
  (selectedArticleId, articleIds) => articleIds.includes(selectedArticleId)
)

export const internalArticle$ = (_, props) =>
  get(props, "data.contentfulArticle", {})

export const internalArticleEntity$ = createSelector(
  [internalArticle$],
  state => state
)

export const articleEntities$ = createSelector(
  [entitiesState],
  state => state.articles
)

export const recentArticles$ = createSelector([articleEntities$], offers =>
  Object.values(offers)
    .sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate))
    .slice(0, 4)
)

export const articleIds$ = createSelector(
  [entitiesState],
  state => state.articles
)

const pageInfo$ = createSelector([pageInfoState], state => state.articles)

// filter the article entities by the selected article type
export const filteredArticleEntities$ = createSelector(
  [articleEntities$, articlesState, filter$],
  (articles, articlesState, filter) => articles
)

// filter the pageInfo by the selected article type
export const filteredPageInfo$ = createSelector(
  [filter$, pageInfo$],
  (filter, pageInfo) => ({ ...defaultPageInfo, ...pageInfo[filter] })
)

// return an array of the filtered article entities
export const filteredArticlesArray$ = createSelector(
  [filteredArticleEntities$],
  articles => Object.values(articles) // sort here
)

// paginate the filtered articles array
export const paginatedFilteredArticlesArray$ = createSelector(
  [filteredArticlesArray$, filteredPageInfo$],
  (articles, pageInfo) => articles.slice(0, pageInfo.offset + DEFAULT_PAGE_SIZE)
)

// filter the articles for an article type by the search term
export const searchResults$ = createSelector(
  [filteredArticlesArray$, search$],
  (articles, search) => {
    const results = articles.filter(article => {
      if (!search) return true
      return searchableTextFromProperties(SearchFields.ARTICLES, article)
        .toLowerCase()
        .includes(search.toLowerCase())
    })
    return results
  }
)

// paginate the search results
export const paginatedSearchResults$ = createSelector(
  [searchResults$, filteredPageInfo$],
  (articles, pageInfo) => {
    return articles.slice(0, pageInfo.offset + DEFAULT_PAGE_SIZE)
  }
)

export const searchResultsCount$ = createSelector(
  [searchResults$, filteredPageInfo$, search$],
  (articles, pageInfo, search) =>
    search === "" ? pageInfo.count : articles.length
)

export const canLoadMore$ = createSelector(
  [filteredPageInfo$, searchResultsCount$],
  (pageInfo, searchResultCount) =>
    pageInfo.offset + DEFAULT_PAGE_SIZE < searchResultCount
)
